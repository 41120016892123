import React, { useState, useEffect } from 'react';
import { Layout } from 'components';
import { container } from './404.module.scss';

const Embed = ({ location }) => {
  const useHtml = false;
  const __html = `
<script>
console.log('hello world');
window.addEventListener('message', (ev) => {
console.log('getting event', ev);
      if (!ev?.data?.frameHeight) return;
      const iframe = document.querySelector('#driva-iframe');
      if (iframe) {
      	iframe.style.height = ev.data.frameHeight + 'px';
      }
   	});
</script>
<iframe
  style="width:100%; max-width: 500px, border-radius: 8px" id="driva-iframe" src="https://embed.driva.com.au/vehicle/?partner_id=fe4985fe-4ca7-4fab-9db1-7eef34dbf00e" allow-top-navigation="true" title="driva-iframe" width="420" height="100"/>
 
  `;
  useEffect(() => {
    if (!useHtml) {
      window.addEventListener('message', (ev) => {
        if (!ev?.data?.frameHeight) return;
        const iframe = document.querySelector('#driva-iframe-react');
        iframe.style.height = `${ev.data.frameHeight}px`;
      });
    }
  }, []);

  return (
    <Layout location={location}>
      {useHtml ? (
        <div className={`container ${container}`} dangerouslySetInnerHTML={{ __html }} />
      ) : (
        <div className={`container ${container}`}>
          <iframe
            style={{ width: '100%', maxWidth: 500, borderRadius: '8px', border: '#d8dadc 1px solid' }}
            id="driva-iframe-react"
            src="https://embed.driva.com.au/vehicle/?partner_id=6530d9da-eda8-43eb-9671-a4f354f675b0"
            allow-top-navigation="true"
            title="driva-iframe"
            width="420"
            height="677"
            scrolling="no"
          />
        </div>
      )}
    </Layout>
  );
};

export default Embed;
